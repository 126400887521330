<template>
  <div>
    <div class="flex md12">
      <va-card :title="'Procedure Details'">
        <div class="row align--center">
          <div class="flex xs12 md3">
            <va-input
              :value="term"
              :placeholder="'Search Users'"
              @input="search"
              removable
            >
              <va-icon name="fa fa-search" slot="prepend" />
            </va-input>
          </div>
          <div class="flex xs12 md3 mb-3">
            <!-- create button and allign right -->
          </div>
          <div class=" flex xs12 md3 offset--md3">
            <va-select
              v-model="perPage"
              :label="$t('tables.perPage')"
              :options="perPageOptions"
              no-clear
            />
          </div>
        </div>

        <va-data-table
          :fields="fields"
          :data="users"
          :per-page="parseInt(perPage)"
          :loading="loading"
          clickable
          hoverable
          class="overflow-hidden"
        >
          <template slot="uid" slot-scope="props">
            <span
              @click="showField('User Uid', props.rowData.uid)"
              :title="props.rowData.uid"
              >{{ props.rowData.uid }}</span
            >
          </template>
          <template slot="registration_email" slot-scope="props">
            <span
              @click="
                showField(
                  'Registration E-mail',
                  props.rowData.registration_email
                )
              "
              :title="props.rowData.registration_email"
              >{{ props.rowData.registration_email }}</span
            >
          </template>

          <template slot="actions" slot-scope="props">
            <div class="flex row">
              <va-button
                @click="showDetails(props.rowData)"
                title="View Details"
                color="info"
                icon="fa fa-info-circle"
              />
            </div>
          </template>
        </va-data-table>
        <va-button @click="loadData()" color="primary">
          <i
            class="va-icon iconicstroke iconicstroke-bolt"
            style="margin-right: 1em;"
          />
          Refresh
        </va-button>
        <va-button
          @click="
            showField('Default Procedure Password', procedure.defaultPassword)
          "
          color="primary"
        >
          <i class="fa fa-key" style="margin-right: 1em;" /> Default Password
        </va-button>
        <va-button @click="back()" color="primary" class="mb-1 mt-3">
          <i
            class="va-icon iconicstroke iconicstroke-arrow-left"
            style="margin-right: 1em;"
          />
          Back
        </va-button>
      </va-card>
    </div>
  </div>
</template>

<script>
import { debounce } from "lodash";
import moment from "moment";
import axios from "@/scripts/interceptor.js";
import firebase from "firebase";
export default {
  data() {
    return {
      email: "",
      loading: true,
      term: null,
      users: [],
      perPage: "10",
      perPageOptions: ["10", "50", "100", "250"],
      procedure: {}
    };
  },
  async created() {
    await this.loadData();
  },
  computed: {
    fields() {
      return [
        {
          name: "__slot:uid",
          title: "User Uid",
          sortField: "uid",
          width: "40%"
        },
        {
          name: "__slot:registration_email",
          title: "Registration Email",
          sortField: "registration_email",
          width: "40%"
        },
        {
          name: "__slot:actions",
          title: "Actions",
          width: "20%"
        }
      ];
    },
    filteredData() {
      return this.users;
    }
  },
  methods: {
    async back() {
      let uid = this.procedure.organization || this.$route.params.orguid
      if(!uid) {
        this.$router.go(-1);
      } else {
        this.$router.push(`/organizations/procedure/list/${uid}`)
      }
    },
    async loadData() {
      this.loading = true;
      this.users = [];
      const uid = this.$route.params.uid;
      const orderFormsResult = await axios.get(
        `/api/organizations/procedure/details/${uid}`
      );
      if (orderFormsResult.data.data) {
        this.procedure = orderFormsResult?.data?.data;
        this.users = orderFormsResult?.data?.data?.accounts;
      }
      this.loading = false;
    },
    showField(title, text) {
      //show swal with the text
      this.$swal({
        title: title,
        icon: "info",
        html: text,
        confirmButtonText: "Close"
      });
    },
    showDetails(user) {
      this.$swal({
        title: "User Details",
        html: `
          <div class="row justify-center">
            <div class="col-md-6">
              <strong>User UID:</strong> ${user.uid}
            </div>
            <div class="col-md-6">
              <strong>User Registration E-mail:</strong> ${user.registration_email}
            </div>
          </div>
        `,
        confirmButtonText: "More Details",
        cancelButtonText: "Close",
        showCancelButton: true,
      }).then ((result) => {
        if (result.isConfirmed) {
          this.$router.push(`/users/edituser/${user.uid}`);
        }
      });
    },
    search: debounce(function(term) {
      /* eslint-disable */
      this.users = term;
    }, 100)
  }
};
</script>
<style>
.overflow-hidden {
  overflow: hidden !important;
}
</style>
